
























import {Component, Vue} from 'vue-property-decorator';
import {reindexQuestions} from '@/firebase/models/search';
import {sync} from '@/firebase/models/elf';

@Component
export default class Utilities extends Vue {

    loading = false;
    syncing = false;
    data = {
        collections: ['LearningFeature', 'LessonQA', 'PracticeExam', 'TargetedReview', 'TrueFalse'],
        clearIndex: true,
    };

    reIndex() {
        this.loading = true;
        reindexQuestions(this.data).then((data) => {
            console.log(data);
            this.loading = false;
        });
    }

    syncELF() {
        this.syncing = true;
        sync().then((data: any) => {
            console.log(data);
            this.syncing = false;
        });
    }

}
