// get all users
import {functions} from '@/firebase';

const reIndexFunction = functions.httpsCallable('reindexQuestions');

const reindexQuestions = (data: any) => reIndexFunction(data)
  .then((result) => result.data)
  .catch((error) => console.error(error));

export {reindexQuestions};
